import { selectedModel, selectedVersion } from '../../gqlCommon/graphql/cache';
import IDimensions from '../../gqlCommon/model/IDimensions';
import { GetCustomizeCar } from '../../gqlCustomise/graphql/queries/__generated__/GetCustomizeCar';
import { GetTrims } from '../../gqlProductCatalog/graphql/queries/__generated__/GetTrims';
import { GetSpecPackTrimLevel } from '../../trimLevels/graphql/queries/__generated__/GetSpecPackTrimLevel';
import { GetTrimDetail } from '../../trimLevels/graphql/queries/__generated__/GetTrimDetail';
import VariantDataLayer, { SpeckPackTrimType } from '../utils/dataLayer/graphql/variantDataLayer';
import ConfigureDataLayer, { GetCustomizeCarType } from '../utils/dataLayer/graphql/configureDataLayer';
import CatalogDataLayer, { GetTrimsType } from '../utils/dataLayer/graphql/catalogDataLayer';
import { CreateDealFromCarId } from '../../gqlCustomise/graphql/mutations/__generated__/CreateDealFromCarId';
import CreateDealDataLayer, { CreateDealDataLayerTypes } from '../utils/dxpDataLayer/graphql/createDealDataLayer';
import { saveDXPLayerDataOnLocalStorage } from '../../../../common/utilities/saveDxpDataLayerOnLocalStorage';

type DxpDataLayerInterceptorTypes = {
  operationName: string;
  response: GetTrimDetail | GetCustomizeCar | CreateDealFromCarId;
  dimensions: IDimensions;
  dxpDataLayer: boolean;
};

type DataLayerTypes = {
  operationName: string;
  response: GetTrims | GetSpecPackTrimLevel | GetCustomizeCar;
  variables: unknown;
  dimension: IDimensions;
};

const dataLayerInterceptor = {
  dxpInterceptor: ({ operationName, response, dimensions, dxpDataLayer }: DxpDataLayerInterceptorTypes) => {
    let data;

    switch (operationName) {
      case 'GetTrimDetail':
        data = response as GetTrimDetail;
        selectedModel(data.trim.attributes.label);
        break;
      case 'CreateDealFromCarId':
        CreateDealDataLayer(<CreateDealDataLayerTypes>{
          data: response,
        });
        break;
      case 'GetCustomizeCar':
        try {
          data = response as GetCustomizeCar;
          const {
            customizeCar: { car, prices },
          } = data;
          saveDXPLayerDataOnLocalStorage({
            dxpDataLayer: dxpDataLayer,
            dimensions: dimensions,
            model: car?.bodyStyle?.attributes?.shortname,
            totalPrice: prices?.totalPrice,
            vehiclePrice: prices?.carPrice?.originalPrice,
            accessoriesPrice: prices?.totalFeatures,
            versions: car?.specPack?.attributes?.shortname,
            keyspecs: null,
          });
          selectedModel(car?.bodyStyle?.attributes?.shortname);
          selectedVersion(car?.specPack?.attributes?.shortname);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('DataLayer error', err);
        }
        break;
      default:
        break;
    }
  },
  interceptor: ({ operationName, response, variables, dimension }: DataLayerTypes) => {
    switch (operationName) {
      case 'GetTrims':
        CatalogDataLayer(<GetTrimsType>{
          data: response,
          variables: variables,
          dimension: dimension,
        });
        break;
      case 'GetSpecPackTrimLevel':
        VariantDataLayer(<SpeckPackTrimType>{
          data: response,
          variables: variables,
          dimension: dimension,
        });
        break;
      case 'GetCustomizeCar':
        ConfigureDataLayer(<GetCustomizeCarType>{
          data: response,
          variables: variables,
          dimension: dimension,
        });
        break;
      default:
        break;
    }
  },
};

export default dataLayerInterceptor;
