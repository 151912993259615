import { DxpDataLayerEvent, DxpDataLayerEventNames, DxpDataLayerType } from "./customDatalayerInterface";
import { CustomDxpWindow } from "./custom.window";
import { getReferrer } from "./dataLayerHelpers";
import dxpDataLayerToggleVerification from "./dxpDataLayerToggleVerification";

declare let window: CustomDxpWindow;

const dxpDigitalDataAssignment = (data: DxpDataLayerType) => {
  const isDxpDataLayer = dxpDataLayerToggleVerification();
  if (isDxpDataLayer) {
    try {
      const lastIndex = window.dataLayer.length - 1;
      const lastEvent = window.dataLayer[lastIndex];
      const isLastIndex = lastEvent?.eventName === data.eventName;

      if (isLastIndex) {
        window.dataLayer[lastIndex] = {
          ...lastEvent,
          event: DxpDataLayerEvent.CONFIGURATOR,
          pageTitle: document.title,
          pageName: window.location.pathname,
          pageLocation: window.location.pathname,
          referralPage: getReferrer().from,
          ...data,
        };
      } else {
        window.dataLayer.push({
          event: DxpDataLayerEvent.CONFIGURATOR,
          pageTitle: document.title,
          pageName: window.location.pathname,
          pageLocation: window.location.pathname,
          referralPage: getReferrer().from,
          ...data,
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("DataLayer error", err);
    }
  }
};

export const updateDxpDataLayer = (data: DxpDataLayerType, updateKey: keyof DxpDataLayerType, updateValue: string): string | undefined => {
  if (updateKey === "accessories") {
    const filteredAccessories: DxpDataLayerType[] =
      window.dataLayer.filter((data: DxpDataLayerType) => {
        return data.eventName === DxpDataLayerEventNames.CONFIGURATOR_ACCESSORIES;
      }) || [];

    if (!filteredAccessories.length) {
      dxpDigitalDataAssignment({
        ...data,
        accessories: updateValue,
      });

      return updateValue;
    }
    let accessories = filteredAccessories[filteredAccessories.length - 1].accessories;

    let acc = accessories.split(',');
    if (accessories?.includes(updateValue)) {
      accessories = acc.filter((accessory) => {
        return  accessory != updateValue;
      }).toString();
    } else {
      acc = [updateValue, ...acc];
      accessories = acc.toString();
    }
    if (!acc[acc.length - 1]) {
      accessories = accessories.slice(0, -1);
    }
    data.accessories = accessories;

    dxpDigitalDataAssignment(data);

    return accessories;
  }
};

export default dxpDigitalDataAssignment;
