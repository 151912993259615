import {
  DataLayerEvent,
  DataLayerFormName,
  DataLayerFormStage,
  DesignType,
  InteriorExteriorType,
} from '../../../../../../common/utilities/customDatalayerInterface';
import digitalDataAssignment from '../../../../../../common/utilities/digitalDataAssignment';
import {
  GetCustomizeCar,
  GetCustomizeCarVariables,
} from '../../../../gqlCustomise/graphql/queries/__generated__/GetCustomizeCar';
import dataLayerToggleVerification from '../../../../../../common/utilities/dataLayerToggleVerification';
import priceExtractionHandler from '../priceExtractionHandler';
import IDimensions from '../../../../gqlCommon/model/IDimensions';
import { customerStorage } from '../../../../../../common/service/customerStorage';

export interface GetCustomizeCarType {
  data: GetCustomizeCar;
  variables: GetCustomizeCarVariables;
  dimension: IDimensions;
}

const ConfigureDataLayer = ({ data, variables, dimension }: GetCustomizeCarType): void => {
  try {
    const isDataLayer = dataLayerToggleVerification(dimension);
    if (isDataLayer) {
      const customerStorageValue = customerStorage.getCustomerData(dimension);
      const keyFeatures: {
        id: number | null;
        name: string | null;
        code: string | null;
        category: string | null;
        price: {
          totalPrice: number | null;
          currency: string | null;
        };
      }[] = [];

      const standardFeatures: {
        id: number | null;
        name: string | null;
        code: string | null;
        category: string | null;
        price?: {
          totalPrice: number | null;
          currency: string | null;
        };
      }[] = [];

      let interior: InteriorExteriorType;

      let exterior: InteriorExteriorType;

      const selectedFeatures = data?.customizeCar?.selectedFeatures?.selected;
      const {
        prices: PriceDetail,
        car: customizeCarData,
        car: { engine, bodyStyle: bodyStyleDetail },
        prices: { carPrice: carPriceDetail },
        availableFeatures: availableFeaturesData,
      } = data?.customizeCar || {};

      const { attributes: engineAttributes } = engine || {};

      /* key features data abstraction */
      availableFeaturesData?.forEach(item => {
        if (selectedFeatures?.indexOf(item.code) !== -1) {
          keyFeatures.push({
            id: null,
            name: item.label,
            code: item.code,
            category: item.categoryCode,
            price: {
              totalPrice: item.prices[0]?.money.amount,
              currency: item.prices[0]?.money.currency,
            },
          });
        }
      });

      /* standard feature data abstraction */
      customizeCarData?.standardFeatures?.forEach(item => {
        standardFeatures.push({
          id: item.id,
          name: item.shortname,
          code: item.code,
          category: item.categoryCode,
        });
      });

      /*  exterior and interior data abstraction */
      selectedFeatures?.forEach(code => {
        const currentFeature = data?.customizeCar?.availableFeatures.find(item => code === item.code);
        if (currentFeature) {
          const currentFeatureAttributes = {
            colour: currentFeature.label,
            code: currentFeature.code,
            price: {
              totalPrice: currentFeature.prices[0]?.money?.amount,
              currency: currentFeature.prices[0]?.money?.currency,
            },
          };

          if (currentFeature.categoryCode === DesignType.INTERIOR) {
            interior = currentFeatureAttributes;
          } else if (currentFeature.categoryCode?.includes(DesignType.SUBARU_EXTERIOR)) {
            exterior = currentFeatureAttributes;
          }
        }
      });

      const discounts = carPriceDetail?.offers?.discounts[0];

      const originalPrice = priceExtractionHandler(carPriceDetail?.originalPrice, dimension).primaryPrice;
      const finalPrice = priceExtractionHandler(carPriceDetail?.finalPrice, dimension).primaryPrice;

      digitalDataAssignment({
        event: DataLayerEvent.FORM_NAVIGATE,
        form: {
          name: DataLayerFormName.CONFIGURATOR,
          stage: DataLayerFormStage.CONFIGURE_CAR,
          details: {
            configurator: {
              selectedSpec: [
                {
                  nameplate: {
                    code: customizeCarData?.code,
                    name: customizeCarData?.attributes?.vehicleDisplayName,
                    modelYear: customizeCarData?.model?.attributes?.modelyear,
                    brand: customizeCarData?.trim?.attributes?.brand,
                    description: customizeCarData?.model?.attributes?.longdescription,
                  },
                  specPack: {
                    code: customizeCarData?.specPack?.attributes?.nviccode,
                    name: customizeCarData?.specPack?.attributes?.shortname,
                  },
                  engine: {
                    description: engineAttributes?.longdescription,
                    fuelType: engineAttributes?.fuelType,
                    fuelLabel: engineAttributes?.fuelLabel,
                    powerTrain: engineAttributes?.powertrain,
                    capacity: engineAttributes?.capacity,
                    maximumPower: engineAttributes?.maximumpower,
                    enginePower: engineAttributes?.enginepower,
                    maximumTorque: engineAttributes?.maximumtorque,
                    transmission: engineAttributes?.transmissionLabel,
                    transmissionType: engineAttributes?.transmissionType,
                  },
                  exterior: exterior,
                  interior: interior,
                  bodyType: {
                    bodyStyleType: bodyStyleDetail?.attributes.bodyStyleType,
                    description: bodyStyleDetail?.attributes.longdescription,
                    code: bodyStyleDetail?.attributes.oemcode,
                    price: {
                      totalPrice: null,
                      currency: null,
                    },
                  },
                  standardFeatures: standardFeatures,
                  addOnFeatures: keyFeatures,
                  prices: [
                    {
                      carPrice: {
                        originalPrice: originalPrice,
                        totalDiscount: carPriceDetail?.totalDiscount[0]?.amount,
                        finalPrice: finalPrice,
                        offers: [{ amount: discounts?.value[0]?.amount }],
                      },
                      totalFeatures: null,
                      totalConfiguration: null,
                      totalPrice: PriceDetail?.totalPrice[0]?.money?.amount,
                      currency: PriceDetail?.totalPrice[0]?.money?.currency,
                      taxes: [
                        {
                          amount: PriceDetail?.carPrice?.finalPrice[1]?.taxes[0]?.money?.amount,
                          type: PriceDetail?.carPrice?.finalPrice[1]?.taxes[0]?.type,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        },
        user: {
          postCode: variables?.postCode,
          preferredDealership: customerStorageValue?.selectedDealer?.label,
        },
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('DataLayer error', err);
  }
};

export default ConfigureDataLayer;
