import { IPriceOverride } from '../model/IPrice';

export interface DataLayerType {
  event: DataLayerEvent;
  form: {
    name: DataLayerFormName;
    stage: DataLayerFormStage;
    details?: DataLayerDetailsType;
    field?: DataLayerFormFieldType;
  };
  user?: DataLayerUserType;
}

export interface DxpDataLayerType {
  event?: DxpDataLayerEvent;
  eventName?: DxpDataLayerEventNames;
  pageName?: string;
  pageTitle?: string;
  pageLocation?: string;
  referralPage?: string;
  pageDescription?: string;
  model?: string;
  motor?: string;
  versions?: string;
  color?: string;
  interiorColor?: string;
  accessories?: string;
  dealerPreference?: string;
  exteriorColor?: string;
  keyspecs?: string;
  financingType?: string;
  repaymentChoice?: string;
  transmission?: string;
  bodyType?: string;
  engineType?: string;
  seatCapacity?: number | null;
  options?: string;
  servicePlan?: string | null;
  paymentMethod?: string;
  creditType?: string;
  vehiclePrice?: number | null;
  accessoriesPrice?: number | null;
  totalPrice?: number | null;
  downPayment?: number | null;
  downPaymentPercentage?: number | null;
  paymentPeriod?: number | null;
  financeAmount?: number | null;
  residualValue?: number | null;
  flatRate?: number | null;
  interestRate?: number | null;
  comparisonRate?: number | null;
  monthlyPayment?: number | null;
  DXPProduct?: string;
  nextSteps?: string[];
  stepName?: string;
}

enum FinanceResultType {
  cash = 'cash',
  error = 'error',
  finance = 'finance',
  na = 'na',
  not_applicable = 'not_applicable',
}

export interface DataLayerDetailsType {
  configurator?: {
    financeOption?: FinanceResultType;
    modelList?: CatalogModalListType[] | CatalogModalListTypeRest[];
    dealId?: string | null;
    specPacks?: SpeckPackListType[];
    selectedSpec?: SelectedSpecPackListType[];
  };
  finance?: {
    amountPayable?: number | null;
    cashDeposit?: number | null;
    comparisonRate?: number | null;
    financeType?: string | null;
    homeOwner?: boolean | string | null;
    interestRate?: number | null;
    loanFees?: number | null;
    loanTerm?: number | null;
    paymentMethod: FinanceResultType;
    purchaseAmount?: number | null;
  };
}

export const enum DxpDataLayerEvent {
  CONFIGURATOR = 'Configurator',
}

export const enum DxpDataLayerEventNames {
  CONFIGURATOR_ENTRY = 'CNF_EntryPoint',
  CONFIGURATOR_MODEL = 'CNF_ModelClicked',
  CONFIGURATOR_MOTOR = 'CNF_MotorClicked',
  CONFIGURATOR_VERSIONS = 'CNF_VersionsClicked',
  CONFIGURATOR_EXTERIOR = 'CNF_ExteriorColorsClicked',
  CONFIGURATOR_INTERIOR = 'CNF_InteriorColorsClicked',
  CONFIGURATOR_ACCESSORIES = 'CNF_AccessoriesClicked',
  CONFIGURATOR_CALCULATE_BUTTON = 'CNF_CalculateButtonClicked',
  CONFIGURATOR_TEST_DRIVE = 'CNF_TestDriveClicked',
  CHECKOUT_LEAD_QUOTE = 'CNF_LeadQuote',
  CONFIGURATOR_QUOTE = 'CNF_QuoteClicked',
  CNF_DEALERS_CLICKED = 'CNF_DealersClicked',
}

export const enum DataLayerDXPProducts {
  FINANCE_CALCULATOR = 'Finance Calculator',
}

export const enum DataLayerEvent {
  FORM_NAVIGATE = '_formNavigate',
  FORM_INTERACT = '_formInteract',
}

export const enum DataLayerFormName {
  CONFIGURATOR = 'configurator',
  REPAYMENT_CALCULATOR = 'repayment calculator',
}

export const enum DataLayerFormStage {
  CATALOG = 'catalog',
  CONTINUE_CHECKOUT = 'continue',
  CONFIGURE_CAR = 'configure your car',
  VARIANT_CONFIGURATOR = 'variant',
  PAYMENT_DISPLAY = 'payment method displayed',
  CHECKOUT = 'checkout',
  CHECKOUT_CONFIRMATION = 'enquire submitted',
  DEALER = 'enquire select retailer',
  ENQUIRY_FORM = 'enquire customer information',
}
export const enum DesignType {
  INTERIOR = 'subaru_interior',
  SUBARU_EXTERIOR = 'subaru_colour',
}

export const enum FormFieldName {
  COLOR = 'colour',
  SPECKPACK = 'specPack',
  LOANTERM = 'loan term',
}

interface DataLayerFormFieldType {
  name: FormFieldName | string;
  value?: string | boolean | null;
}

interface DataLayerUserType {
  postCode?: string | null;
  emailHashed?: string | null;
  inchid?: DataLayerInchIdType | null;
  preferredDealership?: string;
}

interface DataLayerInchIdType {
  acidPrid?: string | null;
  prid?: string | null;
  loggedIn?: boolean | null;
}

interface CatalogModalListType {
  model: string;
  driveAwayPricing: IPriceOverride;
  manufacturerListPricing: IPriceOverride;
}
[];

export interface CatalogModalListTypeRest {
  model: string;
  driveAwayPricing: number;
  manufacturerListPricing: number;
}

export interface SelectedSpecPackListType {
  nameplate?: {
    code: string | null;
    name: string | null;
    modelYear: string | null;
    brand: string | null;
    description: string | null;
  };
  specPack?: {
    code: string | null;
    name: string | null;
  };
  engine?: {
    description?: string | null;
    fuelType?: string | null;
    fuelLabel?: string | null;
    powerTrain?: string | null;
    capacity?: string | null;
    maximumPower?: string | null;
    enginePower?: string | null;
    maximumTorque?: string | null;
    transmission?: string | null;
    transmissionType?: string | null;
  };
  exterior?: InteriorExteriorType;
  interior?: InteriorExteriorType;
  bodyType?: {
    bodyStyleType: string | null;
    description?: string | null;
    code?: string | null;
    price?: {
      totalPrice: number | null;
      currency: string | null;
    };
  };
  standardFeatures?: FeaturesType[];
  addOnFeatures?: FeaturesType[];
  prices?: PriceType[];
  trim?: {
    attributes: {
      label: string | null;
      marketingprefix: string | null;
    };
  };
}

export interface SpeckPackListType {
  nameplate: {
    code?: string | null;
    name?: string | null;
    modelYear: string | null;
    brand?: string | null;
    description?: string | null;
  };
  specPack?: {
    code?: string | null;
    name?: string | null;
  };
  engine?: {
    description?: string | null;
    fuelType?: string | null;
    fuelLabel?: string | null;
    powerTrain?: string | null;
    capacity?: string | null;
    maximumPower?: string | null;
    enginePower?: string | null;
    maximumTorque?: string | null;
    transmission?: string | null;
    transmissionType?: string | null;
  };
  exterior?: InteriorExteriorType;
  interior?: InteriorExteriorType;
  bodyType?: {
    bodyStyleType?: string | null;
    description?: string | null;
    code?: string | null;
    price?: {
      totalPrice: number | null;
      currency: string | null;
    };
  };
  standardFeatures?: FeaturesType[];
  addOnFeatures: FeaturesType[];
  prices: PriceType[];
}

export interface InteriorExteriorType {
  colour: string | null;
  code: string | null;
  price?: {
    totalPrice: number | null;
    currency: string | null;
  };
}

export interface FeaturesType {
  id?: number | null;
  name?: string | null;
  code?: string | null;
  category?: string | null;
  price?: {
    totalPrice: number | null;
    currency: string | null;
  };
}

export interface PriceType {
  carPrice?: {
    originalPrice?: IPriceOverride | number;
    totalDiscount?: number | null;
    finalPrice?: IPriceOverride | number;
    offers?: OfferType[];
  };
  totalFeatures?: number | null;
  totalConfiguration?: number | null;
  totalPrice?: number | null;
  currency: string | null;
  taxes?: TaxType[];
}

export interface OfferType {
  offer?: string | null;
  amount?: number | null;
}

interface TaxType {
  amount: number | null;
  type: string | null;
}
