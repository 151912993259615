import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
} from 'axios';
import { axiosSetCookieInterceptor, createPostMessageTokenHook } from '@inchcape-dxp/automotive-axios-extensions';
import urlRoute from '../service/urlRoute';
import CONSTANTS from '../constants/constants';
import { SESSION } from '../constants/session';
import IDimensions from '../model/IDimensions';
import RequestCancelledError from '../error/RequestCancelledError';

const deepEqual = require('fast-deep-equal/es6');

export const axiosInstance: AxiosInstance = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

let registeredInterceptors: number[] = [];

const cancelTokens: Record<string, CancelTokenSource[]> = {};
const previousCalls: Record<string, Promise<any>> = {};

const httpClient = {
  configure(cookieDomain: string, dimensions: IDimensions): void {
    registeredInterceptors.forEach(id => axiosInstance.interceptors.request.eject(id));
    registeredInterceptors = [];

    httpClient.configureSessionPersistence(cookieDomain);
    httpClient.configureMultiDimensionQuery(dimensions);
  },

  configureSessionPersistence(cookieDomain: string): void {
    axiosSetCookieInterceptor(
      axiosInstance,
      {
        cookieDomain: cookieDomain || SESSION.COOKIE_DOMAIN,
        cookieName: SESSION.COOKIE_NAME,
        cookieLifetime: SESSION.COOKIE_LIFETIME,
        requestHeaderName: SESSION.HEADER_NAME.toLowerCase(),
        responseHeaderName: SESSION.HEADER_NAME.toLowerCase(),
      },
      {
        onReceive: createPostMessageTokenHook(urlRoute.getBaseUrl(CONSTANTS.PLATFORM_API_URL)),
      },
    );
  },

  configureMultiDimensionQuery(dimensions: IDimensions): void {
    if (deepEqual(dimensions, CONSTANTS.BE_DEFAULT_DIMENSIONS)) {
      return;
    }

    registeredInterceptors.push(
      axiosInstance.interceptors.request.use(config => {
        config.params = {
          ...dimensions,
          ...config.params,
        };
        return config;
      }),
    );
  },

  get<T extends any>(url: string, params?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const promise = axiosInstance.get(url, { params, ...config }).catch(error => this.handleError(error, 'get', url));
    return promise;
  },

  post<T extends any>(url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const promise = axiosInstance.post(url, data, config).catch(error => this.handleError(error, 'post', url));
    return promise;
  },

  patch<T extends any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const promise = axiosInstance.patch(url, data, config).catch(error => this.handleError(error, 'patch', url));
    return promise;
  },

  handleError(error: AxiosError, method: string, url: string): Promise<AxiosResponse> {
    if (axios.isCancel(error)) {
      throw new RequestCancelledError(`Request cancelled on ${method} ${url}.`);
    }

    throw error;
  },

  /**
   * Throttles and cancels previously made calls.
   *
   * Throttling is based on awaiting previous last call before executing the last one. This is useful
   * for reducing concurrency in the backend on state modifying calls.
   * Cancellation happens for cases when there are more than 2 calls currently in progress. All calls
   * before the second last one will be cancelled and not awaited for.
   *
   * @param callId
   *    Use this to distinguish between different call types. Calls having the same id will be
   *    throttled together.
   * @param callback
   *    Do the actual axios call in this callback.
   */
  throttleCall<T>(
    callId: string,
    callback: ({ cancelToken }: { cancelToken: CancelToken }) => Promise<AxiosResponse>,
  ): Promise<AxiosResponse<T>> {
    if (!cancelTokens[callId]) {
      cancelTokens[callId] = [];
    }

    while (cancelTokens[callId].length > 1) {
      cancelTokens[callId].shift().cancel();
    }

    const cancelToken = axios.CancelToken.source();
    cancelTokens[callId].push(cancelToken);

    previousCalls[callId] = (previousCalls[callId] || Promise.resolve())
      .catch(() => {
        /*noop*/
      })
      .then(() => callback({ cancelToken: cancelToken.token }));

    return previousCalls[callId];
  },
};

export default httpClient;
