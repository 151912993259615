import { InMemoryCache, makeVar, ReactiveVar } from '@apollo/client';
import { Filters, FinanceFormInput } from '../../../__generated__/globalTypes';
import ISelectedFeaturesInputVar, {
  SelectedFeaturesInputVarDefaultValue,
} from '../../gqlCustomise/model/ISelectedFeaturesInputVar';

export const selectedSectionsVar: ReactiveVar<Array<{ id: string; value: boolean }>> = makeVar<
  Array<{ id: string; value: boolean }>
>([]);
export const selectedCurrencyVar: ReactiveVar<string> = makeVar<string>('');
export const selectedFeaturesIdVar: ReactiveVar<string[]> = makeVar<string[]>([]);
export const marketingCategoriesVar: ReactiveVar<string[]> = makeVar<string[]>([]);
export const financeFormInputVar: ReactiveVar<FinanceFormInput> = makeVar<FinanceFormInput | null>(null);
export const selectedCarNextFiltersVar: ReactiveVar<Filters> = makeVar<Filters | null>(null);
export const nextFeatureSelectionIdVar: ReactiveVar<string> = makeVar<string | null>(null);
export const searchCarIdVar: ReactiveVar<string> = makeVar<string | null>(null);
export const stockCarIdVar: ReactiveVar<string> = makeVar<string | null>(null);
export const selectedFeaturesInputVar: ReactiveVar<ISelectedFeaturesInputVar> = makeVar<ISelectedFeaturesInputVar>(
  SelectedFeaturesInputVarDefaultValue,
);
export const carCodeVar: ReactiveVar<string> = makeVar<string | null>(null);
export const brochureUrlVar: ReactiveVar<string> = makeVar<string | null>(null);
export const brochureFileVar: ReactiveVar<string> = makeVar<string | null>(null);
export const hasFinanceProviderErrorVar: ReactiveVar<boolean> = makeVar<boolean>(false);
export const insuranceEstimate: ReactiveVar<boolean> = makeVar<boolean>(true);
export const insuranceBlockInput: ReactiveVar<string> = makeVar<string | null>(null);
export const selectedModel: ReactiveVar<string> = makeVar<string | null>(null);
export const selectedMotor: ReactiveVar<string> = makeVar<string | null>(null);
export const selectedVersion: ReactiveVar<string> = makeVar<string | null>(null);
export const selectedExterior: ReactiveVar<string> = makeVar<string | null>(null);
export const selectedInterior: ReactiveVar<string> = makeVar<string | null>(null);
export const selectedAccessories: ReactiveVar<string> = makeVar<string | null>(null);

const cache = new InMemoryCache({
  typePolicies: {
    CatalogResult: {
      fields: {
        financeFormInput: {
          read(): FinanceFormInput {
            return financeFormInputVar();
          },
        },
      },
    },
    TrimLevelVariants: {
      fields: {
        financeFormInput: {
          read(): FinanceFormInput {
            return financeFormInputVar();
          },
        },
        marketingCategories: {
          read(): string[] {
            return marketingCategoriesVar();
          },
        },
      },
    },
    EngineResult: {
      fields: {
        financeFormInput: {
          read(): FinanceFormInput {
            return financeFormInputVar();
          },
        },
      },
    },
    BodyStyleResult: {
      fields: {
        financeFormInput: {
          read(): FinanceFormInput {
            return financeFormInputVar();
          },
        },
      },
    },
    ModelResult: {
      fields: {
        financeFormInput: {
          read(): FinanceFormInput {
            return financeFormInputVar();
          },
        },
      },
    },
    SpecPackResult: {
      fields: {
        financeFormInput: {
          read(): FinanceFormInput {
            return financeFormInputVar();
          },
        },
      },
    },
    CustomizedCarConfiguration: {
      fields: {
        financeFormInput: {
          read(): FinanceFormInput {
            return financeFormInputVar();
          },
        },
        selectedSections: {
          read(): Array<{ id: string; value: boolean }> {
            return selectedSectionsVar();
          },
        },
        selectedCurrency: {
          read(): string {
            return selectedCurrencyVar();
          },
        },
        selectedFeaturesId: {
          read(): string[] {
            return selectedFeaturesIdVar();
          },
        },
        nextFeatureSelectionId: {
          read(): string {
            return nextFeatureSelectionIdVar();
          },
        },
        selectedFeaturesInput: {
          read(): ISelectedFeaturesInputVar {
            return selectedFeaturesInputVar();
          },
        },
        searchCarId: {
          read(): string {
            return searchCarIdVar();
          },
        },
        stockCarId: {
          read(): string {
            return stockCarIdVar();
          },
        },
      },
    },
    BrochureUrlConfiguration: {
      fields: {
        brochureUrl: {
          read(): string {
            return brochureUrlVar();
          },
        },
      },
    },
    insurance: {
      fields: {
        insuranceOption: {
          read(): boolean {
            return insuranceEstimate();
          },
        },
      },
    },
  },
  possibleTypes: {
    StockCar: ['FullStockCar', 'PimStockCar'],
  },
});

export default cache;
